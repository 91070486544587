:root {
  --bg-color: #ffffff;
  --text-color: #000000;
  --secondary-text-color: #536471;
  --border-color: #cfd9de;
  --hover-bg-color: rgba(0, 0, 0, 0.03);
  --twitter-blue: #1da1f2;
  --button-text-color: #ffffff;
  --error-bg-color: #fdd8e5;
  --error-text-color: #86061d;
}

body.dark-mode {
  --bg-color: rgb(21, 32, 43);
  --text-color: #ffffff;
  --secondary-text-color: #8899a6;
  --border-color: #38444d;
  --hover-bg-color: rgba(255, 255, 255, 0.03);
  --twitter-blue: #1da1f2;
  --button-text-color: #ffffff;
  --error-bg-color: #86061d;
  --error-text-color: #fdd8e5;
}

.twitter-search {
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color 0.3s ease;
}

.subtitle {
  color: var(--secondary-text-color);
}

.search-input {
  width: 100%;
  padding: 0.5rem 1rem;
  padding-right: 2.5rem; /* Space for the filter button */
  border: 1px solid var(--border-color);
  border-radius: 9999px;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.search-icon {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--secondary-text-color);
}

.filter-button {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0.25rem;
  cursor: pointer;
  color: var(--secondary-text-color);
  transition: color 0.2s;
}

.filter-button:hover {
  color: var(--twitter-blue);
}

.total-tweets, .search-results {
  color: var(--text-color);
}

.shuffle-button {
  background-color: var(--twitter-blue);
  color: var(--button-text-color);
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  transition: background-color 0.2s;
}

.shuffle-button:hover, .sort-button:hover {
  background-color: var(--twitter-blue);
  opacity: 0.9;
}

.load-more-button {
  width: 100%;
  padding: 0.5rem;
  margin-top: 1rem;
  background-color: var(--hover-bg-color);
  color: var(--text-color);
  border-radius: 9999px;
  transition: background-color 0.2s;
}

.load-more-button:hover {
  opacity: 0.8;
}

.error-message {
  background-color: var(--error-bg-color);
  color: var(--error-text-color);
  border-radius: 0.375rem;
  padding: 1rem;
}

.loading-text {
  color: var(--text-color);
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .twitter-search {
    padding: 1rem;
  }
}

/* Accessibility improvements */
.search-input:focus,
.filter-button:focus,
.shuffle-button:focus,
.load-more-button:focus {
  outline: 2px solid var(--twitter-blue);
  outline-offset: 2px;
}

/* Additional helper classes */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
