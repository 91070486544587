body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  transition: background-color 0.3s ease, color 0.3s ease;
}

body.dark-mode {
  background-color: rgb(21, 32, 43);
  color: #ffffff;
}

#root {
  min-height: 100vh;
}

.app-container {
  min-height: 100vh;
  background-color: inherit;
  color: inherit;
}

.page-title {
  color: inherit;
}

.dark-mode-toggle {
  padding: 0.5rem;
  border-radius: 9999px;
  transition: background-color 0.2s;
}

.dark-mode-toggle:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

body.dark-mode .dark-mode-toggle:hover {
  background-color: rgba(255, 255, 255, 0.1);
}