:root {
    --tweet-border-color: #cfd9de;
    --tweet-shadow-color: rgba(0, 0, 0, 0.1);
    --tweet-hover-bg: rgba(0, 0, 0, 0.03);
    --tweet-name-color: #000;
    --tweet-meta-color: #536471;
    --tweet-text-color: #000;
    --tweet-media-border-color: rgb(207, 217, 222);
    --tweet-quoted-border-color: #cfd9de;
    --tweet-action-color: #536471;
    --tweet-action-hover-color: #1da1f2;
    --tweet-retweet-hover-color: #17bf63;
    --tweet-like-hover-color: #e0245e;
  }
  
  body.dark-mode {
    --tweet-border-color: #38444d;
    --tweet-shadow-color: rgba(0, 0, 0, 0.2);
    --tweet-hover-bg: rgba(255, 255, 255, 0.03);
    --tweet-name-color: #fff;
    --tweet-meta-color: #8899a6;
    --tweet-text-color: #fff;
    --tweet-media-border-color: #38444d;
    --tweet-quoted-border-color: #38444d;
    --tweet-action-color: #8899a6;
  }
  
  .tweet-card {
    margin-bottom: 1rem;
    border: 1px solid var(--tweet-border-color);
    transition: background-color 0.2s;
    box-shadow: 0 1px 3px var(--tweet-shadow-color);
  }
  
  .tweet-card:hover {
    background-color: var(--tweet-hover-bg);
  }
  
  .tweet-name {
    color: var(--tweet-name-color);
  }
  
  .tweet-handle, .tweet-date {
    color: var(--tweet-meta-color);
  }
  
  .tweet-text {
    color: var(--tweet-text-color);
  }
  
  .tweet-media {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
    border: 1px solid var(--tweet-media-border-color);
  }
  
  .video-container {
    position: relative;
  }
  
  .video-overlay {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 16px;
  }
  
  .quoted-tweet {
    border-left: 4px solid var(--tweet-quoted-border-color);
    padding-left: 1rem;
  }
  
  .tweet-actions {
    color: var(--tweet-action-color);
  }
  
  .tweet-action-button {
    display: flex;
    align-items: center;
    transition: color 0.2s;
  }
  
  .tweet-action-button:hover {
    color: var(--tweet-action-hover-color);
  }
  
  .tweet-action-button:nth-child(2):hover {
    color: var(--tweet-retweet-hover-color);
  }
  
  .tweet-action-button:nth-child(3):hover {
    color: var(--tweet-like-hover-color);
  }

  .skeleton-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
  
  .skeleton-profile-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  
  .animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  
  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: .5;
    }
  }
  
  .tweet-media {
    position: relative;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    overflow: hidden;
    border-radius: 16px;
  }
  
  .tweet-media img,
  .tweet-media .skeleton-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .video-container {
    position: relative;
  }
  
  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
  }