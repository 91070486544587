.filter-popover {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #cfd9de;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.dark-mode .filter-popover {
  background-color: #15202b;
  color: #ffffff;
  border-color: #38444d;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.filter-popover h3 {
  color: #000000;
}

.dark-mode .filter-popover h3 {
  color: #ffffff;
}

.filter-popover .twitter-checkbox {
  background-color: #ffffff;
  border-color: #cfd9de;
}

.dark-mode .filter-popover .twitter-checkbox {
  background-color: #15202b;
  border-color: #38444d;
}

.filter-popover input:checked + .twitter-checkbox {
  background-color: #1da1f2;
  border-color: #1da1f2;
}

.dark-mode .filter-popover input:checked + .twitter-checkbox {
  background-color: #1a91da;
  border-color: #1a91da;
}

.filter-popover .group:hover .group-hover\:text-gray-900 {
  color: #1da1f2;
}

.dark-mode .filter-popover span {
  color: #ffffff;
}

.dark-mode .filter-popover .group:hover .group-hover\:text-white {
  color: #1a91da;
}

.filter-popover .border-t {
  border-color: #cfd9de;
}

.dark-mode .filter-popover .border-t {
  border-color: #38444d;
}
svg.text-twitter-blue{
  display:none;
}
