:root {
  --reddit-background: white;
  --reddit-border: #ccc;
  --reddit-text-primary: #1c1c1c;
  --reddit-text-secondary: #787c7e;
  --reddit-upvote: #FF8b60;
  --reddit-downvote: #9494FF;
  --reddit-neutral: #C6C6C6;
  --reddit-hover-bg: rgba(0, 0, 0, 0.03);
  --reddit-link: #336699;
  --reddit-icon-bg: #EFF7FF;
  --reddit-icon-color: #C6C6C6;
  --reddit-card-shadow: rgba(0, 0, 0, 0.1);
}

body.dark-mode {
  --reddit-background: #1a1a1b;
  --reddit-border: #343536;
  --reddit-text-primary: #d7dadc;
  --reddit-text-secondary: #818384;
  --reddit-hover-bg: rgba(255, 255, 255, 0.03);
  --reddit-link: #4fbcff;
  --reddit-icon-bg: #2D2D2E;
  --reddit-icon-color: #818384;
  --reddit-card-shadow: rgba(0, 0, 0, 0.2);
}

.reddit-card {
  margin-bottom: 1rem;
  border: 1px solid var(--reddit-border);
  border-radius: 0.375rem;
  box-shadow: 0 1px 3px var(--reddit-card-shadow);
  transition: background-color 0.2s;
}

.reddit-card:hover {
  background-color: var(--reddit-hover-bg);
}

.reddit-card-content {
  padding: 1rem;
}

.reddit-vote-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0.5rem;
  min-width: 40px;
}

.reddit-vote-button {
  background-color: transparent;
  border: none;
  padding: 0.25rem;
  border-radius: 0.25rem;
  transition: background-color 0.2s;
}

.reddit-upvote-button {
  fill: var(--reddit-neutral);
}

.reddit-upvote-button:hover >svg{
  fill: var(--reddit-upvote);
}

.reddit-downvote-button>svg {
  fill: var(--reddit-neutral);
}

.reddit-downvote-button:hover >svg {
  fill: var(--reddit-downvote);
}

.reddit-vote-count {
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--reddit-text-primary);
  margin: 0.25rem 0;
}

.reddit-post-content {
  flex-grow: 1;
  overflow: hidden;
}

.reddit-post-title {
  font-weight: 500;
  font-size: 1.125rem;
  color: var(--reddit-text-primary);
  margin-bottom: 0.25rem;
  line-height: 1.2;
}

.reddit-post-meta {
  font-size: 0.75rem;
  color: var(--reddit-text-secondary);
  margin-bottom: 0.5rem;
}

.reddit-post-thumbnail {
  float: left;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  max-width: 140px;
  max-height: 140px;
  border-radius: 0.25rem;
}

.reddit-post-text {
  font-size: 0.875rem;
  color: var(--reddit-text-primary);
  overflow-wrap: break-word;
}

.reddit-post-footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: var(--reddit-text-secondary);
}

.reddit-footer-action {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
}

.reddit-footer-action:hover {
  color: var(--reddit-text-primary);
}

.reddit-footer-action svg {
  margin-right: 0.25rem;
}

.reddit-link {
  color: var(--reddit-link);
  text-decoration: none;
}

.reddit-link:hover {
  text-decoration: underline;
}

.reddit-icon-container {
  float: left;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--reddit-icon-bg);
  border-radius: 0.375rem;
}

.reddit-icon-container svg {
  color: var(--reddit-icon-color);
}

.reddit-comment .reddit-post-content {
  padding-left: 0.5rem;
  border-left: 2px solid var(--reddit-border);
}

/* Twitter-like footer action styles */
.reddit-footer-action:hover {
  color: var(--reddit-link);
}

.reddit-post-footer > *:last-child {
  margin-left: auto;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}