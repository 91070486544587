.sort-popover {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #cfd9de;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.dark-mode .sort-popover {
  background-color: #15202b;
  color: #ffffff;
  border-color: #38444d;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.sort-popover h3 {
  color: #000000;
}

.dark-mode .sort-popover h3 {
  color: #ffffff;
}

.sort-popover .twitter-radio {
  background-color: #ffffff;
  border-color: #cfd9de;
}

.dark-mode .sort-popover .twitter-radio {
  background-color: #15202b;
  border-color: #38444d;
}

.sort-popover input:checked + .twitter-radio {
  border-color: #1da1f2;
}

.dark-mode .sort-popover input:checked + .twitter-radio {
  border-color: #1a91da;
}

.sort-popover .group:hover .group-hover\:text-gray-900 {
  color: #1da1f2;
}

.dark-mode .sort-popover span {
  color: #ffffff;
}

.dark-mode .sort-popover .group:hover .group-hover\:text-white {
  color: #1a91da;
}

.twitter-radio .bg-twitter-blue {
  background-color: #1da1f2;
}

.dark-mode .twitter-radio .bg-twitter-blue-dark {
  background-color: #1a91da;
}