.generative-museum {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 120px);
  min-height: 600px;
  margin-top: 20px;
}

/* Cluster Selection UI */
.cluster-selection {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  overflow-y: auto;
  background-color: #f7f9fa;
  border-radius: 8px;
}

.dark-mode .cluster-selection {
  background-color: #15202b;
  color: #ffffff;
}

.cluster-selection h2 {
  color: #1da1f2;
  margin-bottom: 2rem;
  text-align: center;
}

.cluster-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  width: 100%;
  max-width: 1200px;
}

.cluster-item {
  background-color: white;
  border-radius: 8px;
  padding: 1.5rem;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border: 2px solid #e1e8ed;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.dark-mode .cluster-item {
  background-color: #192734;
  border-color: #38444d;
}

.cluster-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(29, 161, 242, 0.2);
  border-color: #1da1f2;
}

.cluster-item h3 {
  color: #1da1f2;
  margin-top: 0;
  margin-bottom: 1rem;
}

.keywords {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  flex: 1;
}

.keyword {
  background-color: rgba(29, 161, 242, 0.1);
  border-radius: 15px;
  padding: 0.3rem 0.8rem;
  font-size: 0.9rem;
  color: #1da1f2;
}

.dark-mode .keyword {
  background-color: rgba(29, 161, 242, 0.2);
  color: white;
}

.cluster-item button {
  margin-top: auto;
  background-color: #1da1f2;
  color: white;
  border: none;
  padding: 0.8rem 1rem;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cluster-item button:hover {
  background-color: #0c8ad6;
}

.all-topics {
  background-color: #f2f9ff;
}

.dark-mode .all-topics {
  background-color: #162d40;
}

.generative-museum.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: black;
  padding: 20px;
  margin-top: 0;
}

.generative-museum.fullscreen .museum-container {
  height: calc(100vh - 160px);
  min-height: 600px;
}

.fullscreen-button, .back-button {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.2s ease;
}

.fullscreen-button {
  top: 15px;
  right: 15px;
}

.back-button {
  top: 15px;
  left: 15px;
}

.fullscreen-button:hover, .back-button:hover {
  background-color: rgba(29, 161, 242, 0.7);
}

.dark-mode .fullscreen-button, .dark-mode .back-button {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border-color: rgba(255, 255, 255, 0.5);
}

.museum-container {
  flex: 1;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000;
  border-radius: 8px;
  overflow: hidden;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #1da1f2;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.museum-controls {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background-color: #f7f9fa;
  border-radius: 8px;
  margin-top: 15px;
  border: 1px solid #e1e8ed;
}

.dark-mode .museum-controls {
  background-color: #15202b;
  border-color: #38444d;
  color: #ffffff;
}

.instructions {
  flex: 1;
}

.instructions h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

.instructions p {
  margin: 5px 0;
  font-size: 14px;
}

.topic-info {
  flex: 1;
  margin-right: 20px;
}

.topic-info h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #1da1f2;
}

.log-container {
  flex: 2;
  margin-left: 20px;
  max-height: 150px;
  overflow-y: auto;
}

.log-container h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #1da1f2;
}

.log-messages {
  font-family: monospace;
  font-size: 12px;
}

.log-message {
  margin-bottom: 5px;
  padding: 3px 0;
}